import { AlertCircleIcon } from "lucide-react";
import { memo } from "react";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader } from "../../../components/ui/dialog";

const BanUserAccountModal = memo(({
  isOpen,
  title = `آیا از مسدود کردن این کاربری اطمینان دارید؟`,
  description,
  onOpenChange,
  onConfirm,
  isLoading = false,
}) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className='sm:max-w-sm'>
        <DialogHeader className='!text-center font-semibold leading-7 text-lg text-[#101828] mt-6'>
          <div className='w-12 h-12 rounded-full grid place-content-center bg-[#FAE9E7] mb-4 mx-auto'>
            <AlertCircleIcon className='w-6 h-6 text-[#CA250B] !m-0' />
          </div>
          {title}
        </DialogHeader>
        {description ? <DialogDescription className='-mt-2 text-center leading-5 text-xs text-[#475467] mb-4'>
          {description}
        </DialogDescription> : null}
        <DialogFooter className='justify-between gap-3'>
          <Button disabled={isLoading} className='w-full' onClick={onConfirm}>
            تایید
          </Button>
          <Button disabled={isLoading} className='w-full' variant='outline' onClick={onOpenChange}>
            انصراف
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
});

BanUserAccountModal.displayName = 'BanUserAccountModal';

export { BanUserAccountModal };

