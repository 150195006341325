import { getCoreRowModel, getFilteredRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../../components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/ui/dialog";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import { useAdminCustomer, useUploadImageFiles } from "../../hooks";
import { notification } from "../../state/action";
import { convertFileToBase64 } from "../../utils/helper-functions";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { columns as tableColumn } from "./components/columns";
import DataTable from "./components/data-table";

const AddCustomerByFile = () => {
  const [file, setFile] = useState(null);
  const [parsedData, setParsedData] = useState([]);
  const [idCards, setIDCards] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadInfo, setUploadInfo] = useState(null);
  const [uploadIdCardsInfo, setUploadIdCardsInfo] = useState(null);
  const [selectedRows, setSelectedRows] = useState({});
  const [expandedRows, setExpandedRows] = useState({});
  const [ibanSelection, setIbanSelection] = useState({});

  const dispatch = useDispatch();

  const columns = tableColumn(selectedRows, expandedRows, setExpandedRows);

  const selectAllRows = (data) => {
    const initialSelectedRows = data.reduce((acc, row, index) => {
      acc[index] = true;
      return acc;
    }, {});
    setSelectedRows(initialSelectedRows);
  }

  const table = useReactTable({
    data: parsedData,
    columns,
    state: {
      pagination: { pageIndex: 0, pageSize: 25 },
      rowSelection: selectedRows,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setSelectedRows,
  });

  const postCustomerFileMutation = useAdminCustomer.usePostCreateListOfCustomers();
  const getPendingCustomers = useAdminCustomer.useShowPendingCustomers();
  const postConfirmCusomer = useAdminCustomer.usePostConfirmCustomer();
  const postImageFiles = useUploadImageFiles.useUploadImageFiles();

  const onSubmitCustomerFile = async () => {
    if (!file) return;

    try {
      const res = await postCustomerFileMutation.mutateAsync({ customerListFile: file });
      setUploadInfo(res.data);
      const response = await getPendingCustomers.mutateAsync();

      selectAllRows(response.data.list)

      setParsedData(response.data.list);
      setIsModalOpen(true);
    } catch (error) {
      dispatch(
        notification({
          message: "Error uploading file",
          type: "err",
          id: new Date().getSeconds(),
        })
      );
    }
  };

  const onSubmitPendingData = async (status) => {
    if (!file) return;
    const allRows = table.getRowModel().rows;

    const checkedRows = allRows.filter((row) => row.getIsSelected());
    const ids = checkedRows.map((row) => ({
      id: row.original.id,
      sheba: ibanSelection[row.original.id] || '',
    }));

    try {
      const res = await postConfirmCusomer.mutateAsync({
        idAndShebaInputDetails: ids,
        customerState: status,
      });
      if (res.isSuccess) {
        setIsModalOpen(false);
        dispatch(
          notification({
            message: "عملیات با موفقیت انجام شد",
            type: "suc",
            id: new Date().getSeconds(),
          })
        );
      }
    } catch (error) {
      dispatch(
        notification({
          message: "e.response.data.Error.Message",
          type: "err",
          id: new Date().getSeconds(),
        })
      );
    }
  };

  const onSubmitIDCards = async () => {
    if (!idCards) return;
    const res = await postImageFiles.mutateAsync({ nationalCardImagesZipFile: idCards });
    if (res.isSuccess) {
      setUploadIdCardsInfo(res.data)
    };
  };

  const renderUploadResults = () => {
    if (Array.isArray(uploadIdCardsInfo?.notExist)) {
      return uploadIdCardsInfo?.notExist?.map((item) => item + ", ")
    }
    return uploadIdCardsInfo?.notExist
  }

  return (
    <div>
      <Navbar displayMenu />
      <div className="w-full flex items-start">
        <div className="hidden md:block">
          <Sidebar />
        </div>
        <div className="w-full p-8 max-md:p-4">
          <h2 className="text-xl font-bold text-gray-800 mb-12">افزودن اطلاعات مشتری</h2>
          <Tabs dir="rtl" defaultValue="customerFile">
            <TabsList>
              <TabsTrigger className="!m-0" value="customerFile">
                فایل مشتریان
              </TabsTrigger>
              <TabsTrigger className="!m-0" value="idCards">
                تصاویر کارت ملی
              </TabsTrigger>
            </TabsList>

            <TabsContent value="customerFile">
              <div className="p-4 grid grid-cols-6 gap-x-4 max-md:grid-cols-1 border border-slate-200 rounded-lg">
                <Label className="col-span-1 inline-block mb-2 !mr-0">فایل مشتریان را انتخاب کنید:</Label>
                <Input
                  className="col-span-3"
                  type="file"
                  name="customerListFile"
                  onChange={async (e) => setFile(await convertFileToBase64(e.target.files[0]))}
                  placeholder="فایل را انتخاب کنید"
                />
                <p className="col-span-2 max-md:hidden text-gray-500 text-sm mt-2">فایل باید شامل لیست مشتریان باشد</p>
              </div>
              <Button onClick={onSubmitCustomerFile} disabled={postCustomerFileMutation.isPending || !file} className="!mr-0 mt-4">
                {postCustomerFileMutation.isPending ? "در حال ارسال..." : "ارسال اطلاعات"}
              </Button>
            </TabsContent>

            <TabsContent value="idCards">
              <div className="p-4 grid grid-cols-6 gap-x-4 max-md:grid-cols-1 border border-slate-200 rounded-lg">
                <Label className="col-span-1 inline-block mb-2 !mr-0">فایل تصویر کارت ملی را انتخاب کنید:</Label>
                <Input
                  className="col-span-3"
                  type="file"
                  name="nationalCardImagesZipFile"
                  onChange={async (e) => setIDCards(await convertFileToBase64(e.target.files?.[0]))}
                  placeholder="فایل را انتخاب کنید"
                  accept=".zip,.rar,.7zip"
                />
                <p className="col-span-2 max-md:hidden text-gray-500 text-sm mt-2">فرمت‌های قابل قبول شامل .rar، .zip و .7zip باشد</p>
              </div>
              {uploadIdCardsInfo ? (
                <div className="mt-6 p-4 border border-slate-200 rounded-lg">
                  <div className="text-sm">
                    تعداد آپلود موفق: <span className="text-green-500">{uploadIdCardsInfo?.savedSuccessfullyCount}</span>
                  </div>
                  <div className="text-sm">
                    مشتریان با اطلاعات اشتباه: <span className="text-red-500">{renderUploadResults()}</span>
                  </div>
                </div>
              ) : null}
              <Button onClick={onSubmitIDCards} disabled={postImageFiles.isPending || !idCards} className="!mr-0 mt-4">
                {postImageFiles.isPending ? "در حال ارسال..." : "ارسال اطلاعات"}
              </Button>
            </TabsContent>
          </Tabs>

          <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
            <DialogContent className="max-w-7xl max-h-[90%] overflow-y-auto">
              <DialogHeader>
                <DialogTitle className="text-right">{uploadInfo ? "نتیجه آپلود" : "نمایش جدول داده‌ها"}</DialogTitle>
              </DialogHeader>
              <div className="overflow-x-auto">
                {parsedData.length > 0 && (
                  <DataTable table={table} expandedRows={expandedRows} ibanSelection={ibanSelection} onIbanCheck={setIbanSelection} />
                )}
              </div>
              {uploadInfo ? (
                <div className="mt-6 p-4 border border-slate-200 rounded-lg">
                  <div className="text-sm">
                    تعداد مشتریان با اطلاعات اشتباه: <span className="text-red-500">{uploadInfo?.unSavedCount ?? 0}</span>
                  </div>
                  <div className="text-sm">
                    مجموع مشتریان ارسال شده: <span className="text-green-500">{uploadInfo?.total ?? 0}</span>
                  </div>
                  <div className="text-sm">
                    سطرهای خطا دار: <span className="text-red-500">{uploadInfo?.unsavedList.map((item) => item + ", ")}</span>
                  </div>
                </div>
              ) : null}
              <DialogFooter className="sm:justify-start">
                <div className="mt-4 flex max-md:flex-col items-center gap-4">
                  <Button className="!m-0 bg-emerald-500 w-24 max-md:w-full" onClick={() => onSubmitPendingData(1)} disabled={postCustomerFileMutation.isPending || !file || !parsedData || postConfirmCusomer.isPending}>
                    {(postCustomerFileMutation.isPending || postConfirmCusomer.isPending) ? "در حال ارسال..." : "تایید"}
                  </Button>
                  <Button className="!m-0 bg-red-500 w-24 max-md:w-full" onClick={() => onSubmitPendingData(3)} disabled={postCustomerFileMutation.isPending || !file || !parsedData || postConfirmCusomer.isPending}>
                    {(postCustomerFileMutation.isPending || postConfirmCusomer.isPending) ? "در حال ارسال..." : "رد"}
                  </Button>
                  <Button className="!m-0 w-24 max-md:w-full" onClick={() => selectAllRows(parsedData)} disabled={!parsedData}>
                    انتخاب همه
                  </Button>
                  <div className="">
                    <h3 className="text-sm">سطرهای انتخاب شده: {table.getSelectedRowModel().flatRows.length}</h3>
                  </div>
                </div>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default AddCustomerByFile;
