import * as XLSX from 'xlsx';
import { BankAccount } from '../types/data-export';

const exportExcel = <T>(data: T[]) => {
    if (!data || data.length === 0) {
      console.error('No data available to export');
      return;
    }

    // Transform data
    const transformedData = data.map((row: any, index) => ({
      'ردیف': index + 1,
      'نام و نام خانوادگی': row.name || '',
      'شماره تلفن': row.mobileNumber || '',
      'کد ملی': row.nationalCode || '',
      'کد پستی': row.zipCode || '',
      'وضعیت کارت ملی': row.isExpired ? 'نامعتبر' : 'معتبر',
      'خارجی': row.isForeign ? 'بله' : 'خیر',
      'حساب‌های بانکی': row.customerBankAccountInfoListModels.length > 0 ? row.customerBankAccountInfoListModels.map((account: BankAccount) => {
        return `${account.bankName}: ${account.shebaNumber}`;
      }).join('\n') : '',
    }));
    try {
      const worksheet = XLSX.utils.json_to_sheet(transformedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      XLSX.writeFile(workbook, 'data.xlsx');
    } catch (error) {
      console.error('Error generating Excel file:', error);
    }
}

  export { exportExcel };

