import { useState } from "react";
import SlideImg1 from "../../assets/image/loading.gif";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { baseFileUrl } from "../../constants";

export function FileUpload({ id, label, name, onFileUpload, uploadedImage, error, description = "" }) {
    const [uploading, setUploading] = useState(false);
  
    const handleFileChange = async (e) => {
      setUploading(true);
      await onFileUpload(e.target.files[0]);
      setUploading(false);
    };
  
    return (
      <div className="grid grid-cols-1 gap-4 mb-4 w-full">
      <div className="flex items-center justify-between col-span-3">
          <Label className="inline-block mb-1 !mr-0" htmlFor={id}>
            {label}
          </Label>
          {error && <p className="text-red-500 text-xs mt-1 text-left">{error}</p>}
        </div>
        <div className="col-span-3">
          <Input type="file" name={name} onChange={handleFileChange} />
          {uploading && <img alt="" className="w-32 aspect-square" src={SlideImg1} />}
          {uploadedImage && <img className="w-32 aspect-[1.285] object-cover mt-2" src={baseFileUrl + uploadedImage} alt="" />}
        </div>
      </div>
    );
  }