import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";
import { memo, useMemo, useState } from "react";
import TableSkeleton from "../../../components/shared/table-skeleton";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { columns as generateColumns } from "./columns";

const DataTable = memo(({
  data,
  page,
  pageSize,
  totalPage,
  onPageChange,
  loading,
  toggleUserStatus,
  onPageSizeChange,
  isChangingStatus
}) => {
  const [sorting, setSorting] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const columns = useMemo(() => generateColumns(null, toggleUserStatus, isChangingStatus), [ toggleUserStatus, isChangingStatus ]);

  const table = useReactTable({
    data,
    columns,
    pageCount: totalPage,
    state: {
      sorting,
      columnVisibility,
      pagination: {
        pageIndex: page - 1,
        pageSize: pageSize,
      },
    },
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
  });

  return (
    <div>
      <div className="rounded-md">
        <Table>
          <TableHeader className="!bg-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <TableHead className="[&>button]:!m-0 [&>button]:max-md:text-xs text-center">
                  ردیف
                </TableHead>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      className="[&>button]:!m-0 [&>button]:max-md:text-xs text-center !w-max"
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {loading ? (
              <TableSkeleton count={pageSize} cols={5} />
            ) : table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => {
                const rowIndex = index + 1;
                const rowClassName = "bg-white";
                return (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className={rowClassName}
                  >
                    <TableCell className="!bg-transparent max-md:text-xs text-center">
                      {rowIndex}
                    </TableCell>
                    {row.getVisibleCells().map((cell, cellIndex) => (
                      <TableCell
                        key={cell.id}
                        className="!bg-transparent max-md:text-xs text-center"
                      >
                        {cellIndex === row.getVisibleCells().length - 1
                          ? flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          : cell.getValue() != null
                          ? flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )
                          : "-"}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center !bg-white"
                >
                  اطلاعاتی یافت نشد.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="w-full flex max-md:flex-col max-md:gap-y-2.5 items-center justify-end gap-x-4 p-2 bg-slate-100 border border-slate-200">
          <div className="flex items-center gap-x-4 max-md:w-full">
            <DropdownMenu dir="rtl">
              <DropdownMenuTrigger asChild className="max-md:w-full">
                <Button variant="outline" className="!m-0">
                  ستون‌ها
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                {table
                  .getAllColumns()
                  .filter(
                    (column) =>
                      column.getCanHide() &&
                      column.columnDef.label !== undefined
                  )
                  .map((column) => {
                    return (
                      <DropdownMenuCheckboxItem
                        key={column.id}
                        className="capitalize"
                        checked={column.getIsVisible()}
                        onCheckedChange={(value) =>
                          column.toggleVisibility(!!value)
                        }
                      >
                        {column.columnDef.label}
                      </DropdownMenuCheckboxItem>
                    );
                  })}
              </DropdownMenuContent>
            </DropdownMenu>
            <Select
              dir="rtl"
              onValueChange={onPageSizeChange}
              value={pageSize.toString()}
            >
              <SelectTrigger className="!m-0 w-[180px] [&>span]:!text-gray-800 [&>svg]:!m-0 max-md:w-full">
                <SelectValue placeholder="تعداد نتایج در صفحه" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem className="[&>span]:!text-gray-800" value="1">
                  1
                </SelectItem>
                <SelectItem className="[&>span]:!text-gray-800" value="5">
                  5
                </SelectItem>
                <SelectItem className="[&>span]:!text-gray-800" value="10">
                  10
                </SelectItem>
                <SelectItem className="[&>span]:!text-gray-800" value="15">
                  15
                </SelectItem>
                <SelectItem className="[&>span]:!text-gray-800" value="20">
                  20
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          <div className="flex gap-x-4 items-center max-md:justify-between max-md:w-full">
            <div className="flex w-[100px] items-center justify-center text-sm font-medium">
              صفحه {page} از {totalPage}
            </div>

            <div className="flex items-center gap-x-2">
              <Button
                variant="outline"
                className="!m-0 hidden h-8 w-8 p-0 lg:flex"
                onClick={() => onPageChange(1)}
                disabled={page === 1 || loading}
              >
                <span className="sr-only">Go to first page</span>
                <ChevronsRight className="!m-0 h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="!m-0 h-8 w-8 p-0"
                onClick={() => onPageChange(page - 1)}
                disabled={page === 1 || loading}
              >
                <span className="sr-only">Go to previous page</span>
                <ChevronRightIcon className="!m-0 h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="!m-0 h-8 w-8 p-0"
                onClick={() => onPageChange(page + 1)}
                disabled={page === totalPage || loading}
              >
                <span className="sr-only">Go to next page</span>
                <ChevronLeftIcon className="!m-0 h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="!m-0 hidden h-8 w-8 p-0 lg:flex"
                onClick={() => onPageChange(totalPage)}
                disabled={page === totalPage || loading}
              >
                <span className="sr-only">Go to last page</span>
                <ChevronsLeft className="!m-0 h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

DataTable.displayName = 'DataTable';

export { DataTable };

