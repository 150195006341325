import { useCallback, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserInfo } from '../../api';
import { userInfo } from '../../state/action';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';


import { useAdminLocalBankAccount } from '../../hooks';
import { columns } from './components/columns';
import { DataTable } from './components/data-table';
import { DeleteBankAccountModal } from "./components/delete.modal";
import TableToolbar from './components/table-toolbar';

function AdminLocalBankAccount() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [bankName, setBankName] = useState("");
    const [deleteId, setDeleteId] = useState(null);
    const [copyIbanIdSuccess, setCopyIbanIdSuccess] = useState(null);

    const getAdminBankAccountQuery = useAdminLocalBankAccount.useGetAdminBankAccount({ page, pageSize })
    const useDeleteAdminBankAccountMutation = useAdminLocalBankAccount.useDeleteAdminBankAccount()

    const onDeleteAccount = useCallback((id) => {
        setDeleteId(id);
    }, [])

    const handleDeleteAccount = useCallback(() => {
        useDeleteAdminBankAccountMutation.mutateAsync({ id: deleteId })
            .then(() => {
                setDeleteId(null);
                getAdminBankAccountQuery.refetch();
            })
            .catch(e => {
                console.log(e);
                setDeleteId(null);
            })
    }, [deleteId, useDeleteAdminBankAccountMutation, getAdminBankAccountQuery])

    const updateURLParams = useCallback((name = '', pageValue = page, pageSizeValue = pageSize) => {
        const params = new URLSearchParams({
            bankName: name,
            page: pageValue.toString(),
            pageSize: pageSizeValue.toString(),
        });
        navigate(`?${params.toString()}`, { replace: true });
    }, [page, pageSize, navigate]);

    const handlePageSizeChange = useCallback((value) => {
        setPageSize(value);
        setPage(1);
        updateURLParams(bankName, 1, value);
    }, [bankName, updateURLParams]);

    const handlePageChange = useCallback((newPage) => {
        setPage(newPage);
        updateURLParams(bankName, newPage, pageSize);
    }, [bankName, pageSize, updateURLParams]);

    const handleSubmitFilters = useCallback((e) => {
        e.preventDefault();
        setPage(1);
        updateURLParams(bankName, 1, pageSize);
    }, [bankName, pageSize, updateURLParams]);

    const removeFilter = useCallback((filterKey) => {
        const params = new URLSearchParams(location.search);
        params.delete(filterKey);

        switch (filterKey) {
            case "bankName":
                setBankName("");
                break;
            default:
                break;
        }

        navigate(`?${params.toString()}`, { replace: true });
    }, [navigate, location.search]);

    const handleCopyIban = useCallback((iban) => {
        navigator.clipboard.writeText(iban).then(() => {
            setCopyIbanIdSuccess(iban);
            setTimeout(() => {
                setCopyIbanIdSuccess(null);
            }, 700);
        });
    }, []);

    const handleEditAccount = useCallback((id) => {
        navigate(`/edit-local-bank-account?accountId=${id}`);
    }, [navigate]);

    useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }
    }, [dispatch, navigate]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const banknameFromParams = params.get("bankName") || "";
        const pageFromParams = parseInt(params.get("page")) || 1;
        const pageSizeFromParams = parseInt(params.get("pageSize")) || 10;

        setBankName(banknameFromParams);
        setPage(pageFromParams);
        setPageSize(pageSizeFromParams);

    }, [location.search, getAdminBankAccountQuery]);

    return (
        <div>
            <Navbar displayMenu />
            <div className="w-full flex items-start">
                <div className="hidden md:block">
                    <Sidebar />
                </div>


                <div className="w-full p-8 max-md:p-4 overflow-x-auto">


                    <h2 className="text-xl font-bold text-gray-800 mb-12">فهرست  حساب های شرکت</h2>
                    <div className="w-full bg-slate-100 border border-slate-200 rounded-lg overflow-hidden">
                        <TableToolbar
                            bankName={bankName}
                            setBankName={setBankName}
                            onRemoveFilter={removeFilter}
                        />

                        <div className="rounded-lg overflow-hidden border-t border-gray-300">
                            <DataTable
                                columns={columns}
                                data={getAdminBankAccountQuery?.data?.list}
                                page={page}
                                pageSize={pageSize}
                                totalPage={getAdminBankAccountQuery?.data?.totalPage}
                                onPageChange={handlePageChange}
                                loading={getAdminBankAccountQuery.isLoading}
                                onDeleteAccount={onDeleteAccount}
                                onPageSizeChange={handlePageSizeChange}
                                onCopyIban={handleCopyIban}
                                onEditBank={handleEditAccount}
                                copyIbanIdSuccess={copyIbanIdSuccess}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <DeleteBankAccountModal isOpen={deleteId !== null} onOpenChange={() => setDeleteId(null)} onConfirm={handleDeleteAccount} isLoading={useDeleteAdminBankAccountMutation.isPending} />
        </div>
    );
};

export default AdminLocalBankAccount;