
import { useQuery } from "@tanstack/react-query";
import { memo } from "react";
import { getBankDetails } from "../../../api";
import { Avatar, AvatarFallback, AvatarImage } from "../../../components/ui/avatar";
import { Button } from "../../../components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Skeleton } from "../../../components/ui/skeleton";

function BankDetailsModal({
  isOpen,
  onOpenChange,
  onEditBank
}) {
  const { data, isLoading } = useQuery({
    queryKey: ['bankDetails', isOpen],
    queryFn: async () => await getBankDetails(isOpen),
  })
  const bank = data?.data.data;

  return (
    <Dialog
      dir="rtl"
      open={isOpen}
      onOpenChange={onOpenChange}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle className="text-center">
            جزئیات
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center justify-between space-x-4 rtl:space-x-reverse">
          <div className="flex items-center space-x-4 rtl:space-x-reverse flex-grow">
            <Avatar className="w-16 h-16">
              <AvatarImage src={`${process.env.REACT_APP_BASE_FILE_URL}/${bank?.bankIcon}`} alt={bank?.bankName} />
              <AvatarFallback>{bank?.bankName?.substring(0, 1)}</AvatarFallback>
            </Avatar>
            <div className="space-y-1 w-full">
              {isLoading ? <Skeleton className="w-40 h-7" /> : <h2 className="text-lg font-semibold">{bank?.bankName}</h2>}
            </div>
          </div>
          <div className="flex max-md:flex-col max-md:items-start items-center gap-1">
            {isLoading ? <Skeleton className="w-full h-5" /> : <p dir="ltr" className="text-sm text-gray-500">{bank?.updateDate}</p>}
          </div>
        </div>

        <div className="space-y-4 mt-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-y-1.5">
              <Label htmlFor="todayTransactionAmount">مبلغ معامله امروز</Label>
              {isLoading ? <Skeleton className="w-full h-10" /> : <Input className="appearance-none pointer-events-none focus-visible:ring-0 border-none" id="todayTransactionAmount" readOnly defaultValue={bank?.bankDetailStatistics?.todayTransactionAmount} />}
            </div>
            <div className="flex flex-col gap-y-1.5">
              <Label htmlFor="todayTransactionCount">تعداد معاملات امروز</Label>
              {isLoading ? <Skeleton className="w-full h-10" /> : <Input className="appearance-none pointer-events-none focus-visible:ring-0 border-none" id="todayTransactionCount" readOnly defaultValue={bank?.bankDetailStatistics?.todayTransactionCount} />}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div className="flex flex-col gap-y-1.5">
              <Label htmlFor="totalTransactionAmount">مقدار کل معاملات</Label>
              {isLoading ? <Skeleton className="w-full h-10" /> : <Input className="appearance-none pointer-events-none focus-visible:ring-0 border-none" id="totalTransactionAmount" readOnly defaultValue={bank?.bankDetailStatistics?.totalTransactionAmount} />}
            </div>

            <div className="flex flex-col gap-y-1.5">
              <Label htmlFor="totalTransactionCount">تعداد کل معاملات</Label>
              {isLoading ? <Skeleton className="w-full h-10" /> : <Input className="appearance-none pointer-events-none focus-visible:ring-0 border-none" id="totalTransactionCount" readOnly defaultValue={bank?.bankDetailStatistics?.totalTransactionCount} />}
            </div>
          </div>

          <div className="flex items-center justify-between gap-2 mt-6">
            <Button className="!m-0 w-24" onClick={() => onEditBank(bank?.id)}>ویرایش</Button>
            <Button onClick={onOpenChange} className="!m-0 w-24" >بستن</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default memo(BankDetailsModal);
