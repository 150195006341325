import { ChevronsUpDownIcon, User2Icon } from "lucide-react";
import { Link } from "react-router-dom";
import { Avatar, AvatarFallback, AvatarImage } from "../../../components/ui/avatar";
import { Button } from "../../../components/ui/button";
import { cn } from "../../../lib/utils";

const handleRowExpansion = (rowId, setExpandedRows) => {
  setExpandedRows((prev) => ({
    ...prev,
    [rowId]: !prev[rowId],
  }));
};

export const columns = (selectedRows, expandedRows, setExpandedRows) => {

  return [
    {
      accessorKey: "name",
      label: 'نام مشتری',
      header: ({ column }) => (
        <Button
          className="text-center w-[200px]"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          نام مشتری
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const avatar = row.original.nationalCardImage;
        const userId = row.original.id;
        const value = row.getValue('name');

        const isSelected = selectedRows[row.index];
        
        if (isSelected && !expandedRows[userId]) {
          handleRowExpansion(userId, setExpandedRows);
        } else if (!isSelected && expandedRows[userId]) {
          handleRowExpansion(userId, setExpandedRows);
        }

        return (
          <div className="flex items-center gap-x-2">
            <Avatar>
              <AvatarImage src={`${process.env.REACT_APP_BASE_FILE_URL}${avatar}`} />
              <AvatarFallback className='text-gray-800'>
                <User2Icon className="text-slate-400 mx-auto" />
              </AvatarFallback>
            </Avatar>
            <Link to={`/edit-customer?userid=${userId}`}>{value}</Link>
          </div>
        );
      },
    },
    {
      accessorKey: "nationalCode",
      label: 'کد ملی مشتری',
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          کد ملی مشتری
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      ),
    },
    {
      accessorKey: "isExpired",
      label: 'وضعیت کارت ملی',
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          وضعیت کارت ملی
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const isExpired = row.getValue("isExpired");
        const value = isExpired ? 'نامعتبر' : 'معتبر';
        return <div className={cn(isExpired ? 'text-red-500' : 'text-green-500', 'text-center font-medium')}>{value}</div>;
      },
    },
    {
      accessorKey: "mobileNumber",
      label: 'شماره موبایل',
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          شماره موبایل
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      ),
    },
    {
      accessorKey: "isForeign",
      label: 'خارجی',
      header: ({ column }) => (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          خارجی
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      ),
      cell: ({ row }) => {
        const isForeign = row.getValue("isForeign");
        const value = isForeign ? 'بله' : 'خیر';
        return <div className={cn(isForeign ? 'text-green-500' : 'text-amber-600', 'text-center font-medium')}>{value}</div>;
      },
    },
  ];
};
