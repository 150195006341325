import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import * as Api from "../api";
import { notification } from "../state/action";

const usePostCreateListOfCustomers = () => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: (params) => Api.PostCreateListOfCustomers(params),
    onError: (e) =>
      dispatch(
        notification({
          message: e.response.data.Error.Message,
          type: "err",
          id: new Date().getSeconds(),
        })
      ),
    onSuccess: () => {
      dispatch(
        notification({
          message: "فایل با موفقیت آپلود شد",
          type: "suc",
          id: new Date().getSeconds(),
        })
      );
    },
  });
  return mutation;
};

const useShowPendingCustomers = () => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: () => Api.GetPendingCustomer(),
    onError: (e) =>
      dispatch(
        notification({
          message: e.response.data.Error.Message,
          type: "err",
          id: new Date().getSeconds(),
        })
      ),
    onSuccess: () => {},
  });
  return mutation;
};

const usePostConfirmCustomer = () => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: (params) => Api.PostConfirmCustomers(params),
    onError: (e) =>
      dispatch(
        notification({
          message: e.response.data.Error.Message,
          type: "err",
          id: new Date().getSeconds(),
        })
      ),
    onSuccess: () => {
      dispatch(
        notification({
          message: "اطلاعات با موفقیت آپلود شد",
          type: "suc",
          id: new Date().getSeconds(),
        })
      );},
  });
  return mutation;
};

export default {
  usePostCreateListOfCustomers,
  useShowPendingCustomers,
  usePostConfirmCustomer
};
