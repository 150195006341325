import { DownloadIcon, FilterIcon, PlusIcon, SearchIcon, XIcon } from "lucide-react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Switch } from "../../../components/ui/switch";

function TableToolbar({
  onRemoveFilter,
  onAddNewCustomer,
  onSearch,
  customerNationalCode,
  setCustomerNationalCode,
  setCustomerFirstFaName,
  setCustomerLastFaName,
  setCustomerFirstEngName,
  setCustomerLastEngName,
  mobileNumber,
  setMobileNumber,
  isForeigner,
  setIsForeigner,
  isBlocked,
  setIsBlocked,
  hasNationalCardImage,
  setHasNationalCardImage,
  onCustomersExport,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const detectLanguageAndSetNames = (value, isFirstName = true) => {
    const farsiRegex = /[\u0600-\u06FF]/;
    const isFarsi = farsiRegex.test(value);

    if (isFirstName) {
      if (isFarsi) {
        setCustomerFirstFaName(value);
        setCustomerFirstEngName("");
      } else {
        setCustomerFirstEngName(value);
        setCustomerFirstFaName("");
      }
    } else {
      if (isFarsi) {
        setCustomerLastFaName(value);
        setCustomerLastEngName("");
      } else {
        setCustomerLastEngName(value);
        setCustomerLastFaName("");
      }
    }
  };

  return (
    <div className="w-full flex max-md:flex-col items-center justify-end max-md:justify-center p-2 gap-x-4">
      <div className="w-full p-2 flex flex-wrap gap-2">
        {urlParams.get("customerNationalCode") && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("customerNationalCode")}>
            <XIcon className="w-3 h-3" />
            کد ملی: {urlParams.get("customerNationalCode")}
          </Badge>
        )}
        {urlParams.get("customerFirstFaName") && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("customerFirstFaName")}>
            <XIcon className="w-3 h-3" />
            نام: {urlParams.get("customerFirstFaName")}
          </Badge>
        )}
        {urlParams.get("customerLastFaName") && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("customerLastFaName")}>
            <XIcon className="w-3 h-3" />
            نام خانوادگی: {urlParams.get("customerLastFaName")}
          </Badge>
        )}
        {urlParams.get("customerFirstEngName") && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("customerFirstEngName")}>
            <XIcon className="w-3 h-3" />
            نام انگلیسی: {urlParams.get("customerFirstEngName")}
          </Badge>
        )}
        {urlParams.get("customerLastEngName") && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("customerLastEngName")}>
            <XIcon className="w-3 h-3" />
            نام خانوادگی انگلیسی: {urlParams.get("customerLastEngName")}
          </Badge>
        )}
        {urlParams.get("mobileNumber") && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("mobileNumber")}>
            <XIcon className="w-3 h-3" />
            موبایل: {urlParams.get("mobileNumber")}
          </Badge>
        )}
        {urlParams.get("isForeigner") && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("isForeigner")}>
            <XIcon className="w-3 h-3" />
            {urlParams.get("isForeigner") === "true" ? "خارجی" : "داخلی"}
          </Badge>
        )}
        {urlParams.get("hasNationalCardImage") && urlParams.get("hasNationalCardImage") !== '2' && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("hasNationalCardImage")}>
            <XIcon className="w-3 h-3" />
            {urlParams.get("hasNationalCardImage") === "1" ? "تصویر کارت ملی دارد" : urlParams.get("hasNationalCardImage") === "0" ? "تصویر کارت ملی ندارد" : null}
          </Badge>
        )}
        {urlParams.get("isBlocked") && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("isBlocked")}>
            <XIcon className="w-3 h-3" />
            {urlParams.get("isBlocked") === "true" ? "مسدود شده" : "مسدود نشده"}
          </Badge>
        )}
      </div>
      <div className="flex max-md:flex-col-reverse items-center gap-4 max-md:w-full">
        <DropdownMenu dir="rtl">
          <DropdownMenuTrigger asChild>
            <Button className="!m-0 flex items-center bg-white hover:bg-slate-200 text-gray-800 rounded-lg border border-slate-200 max-md:w-full">
              <DownloadIcon className="w-4 h-4 shrink-0" />
              <span className="!text-gray-800 text-xs">دریافت اطلاعات</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => onCustomersExport("xlsx")} className="text-gray-800 cursor-pointer">
              <span className="text-gray-800">دریافت با فرمت XLSX</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onCustomersExport("csv")} className="text-gray-800 cursor-pointer">
              <span className="text-gray-800">دریافت با فرمت CSV</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

        <DropdownMenu dir="rtl">
          <DropdownMenuTrigger asChild>
            <Button className="!m-0 flex items-center bg-white hover:bg-slate-200 text-gray-800 rounded-lg border border-slate-200 max-md:w-full">
              <PlusIcon className="w-4 h-4 shrink-0" />
              <span className="!text-gray-800 text-xs">افزودن مشتری</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={onAddNewCustomer} className="text-gray-800 cursor-pointer">
              <span className="text-gray-800">مشتری جدید</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => navigate("/add-new-customer-by-file")} className="text-gray-800 cursor-pointer">
              <span className="text-gray-800">افزودن مشتری از طریق فایل</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Popover>
          <PopoverTrigger className="!m-0 max-md:w-full">
            <Button className="!m-0 flex items-center bg-white hover:bg-slate-200 text-gray-800 rounded-lg border border-slate-200 max-md:w-full">
              <FilterIcon className="w-4 h-4 shrink-0" />
              <span className="!text-gray-800 text-xs">فیلترها</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <form onSubmit={onSearch} className="w-full flex flex-col items-stretch gap-y-2">
              <div>
                <Input defaultValue={urlParams.get("customerNationalCode")}
                  placeholder="کد ملی مشتری"
                  type="text"
                  value={customerNationalCode}
                  onChange={(e) => setCustomerNationalCode(e.target.value)}
                  inputMode="numeric"
                />
              </div>
              <div>
                <Input defaultValue={urlParams.get("customerFirstFaName") || urlParams.get("customerFirstEngName")} placeholder="نام مشتری" type="text" onChange={(e) => detectLanguageAndSetNames(e.target.value, true)} />
              </div>
              <div>
                <Input defaultValue={urlParams.get("customerLastFaName") || urlParams.get("customerLastEngName")} placeholder="نام خانوادگی مشتری" type="text" onChange={(e) => detectLanguageAndSetNames(e.target.value, false)} />
              </div>
              <div>
                <Input defaultValue={urlParams.get("mobileNumber")}
                  placeholder="شماره موبایل"
                  type="tel"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                  inputMode="tel"
                  className="placeholder:text-right"
                />
              </div>
              <div className="w-full flex justify-between items-center">
                <Label htmlFor="foreigner">تصویر کارت ملی</Label>
                <Select
                  dir="rtl"
                  onValueChange={setHasNationalCardImage}
                  value={hasNationalCardImage}
                >
                  <SelectTrigger className="!m-0 w-1/2 [&>span]:!text-gray-800 [&>svg]:!m-0 max-md:w-full">
                    <SelectValue className="text-right" placeholder="تصویر کارت ملی" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem className="[&>span]:!text-gray-800" value="2" >
                      همه
                    </SelectItem>
                    <SelectItem className="[&>span]:!text-gray-800" value="1">
                      دارد
                    </SelectItem>
                    <SelectItem className="[&>span]:!text-gray-800" value="0">
                      ندارد
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="w-full flex justify-between items-center">
                <Label htmlFor="foreigner">خارجی</Label>
                <Switch dir="rtl" checked={isForeigner} onCheckedChange={(value) => setIsForeigner(value)} id="foreigner" />
              </div>
              <div className="w-full flex justify-between items-center">
                <Label htmlFor="blocked">مسدود</Label>
                <Switch dir="rtl" checked={isBlocked} onCheckedChange={(value) => setIsBlocked(value)} id="blocked" />
              </div>
              <Button className="!m-0 flex items-center rounded-lg border border-slate-200" type="submit">
                <SearchIcon className="w-4 h-4 shrink-0" />
                <span className="!text-white">جستجو</span>
              </Button>
            </form>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}

export default TableToolbar;
