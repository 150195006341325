import { MoreVertical } from "lucide-react";
import { memo } from "react";
import { Button } from "../ui/button";
import { Skeleton } from "../ui/skeleton";
import { TableCell, TableRow } from "../ui/table";
import { cn } from "../../lib/utils";

function TableSkeleton({ count = 5, cols = 4, className = '' }) {
  return (
    <>
      {Array.from({ length: count }).map(_ => (<TableRow>
        <TableCell className="bg-white" colSpan={cols}>
          <Skeleton className={cn('w-full h-8 rounded-lg', className)} />
        </TableCell>
        <TableCell className="bg-white">
          <Button variant="ghost" className="h-8 w-8 p-0">
            <MoreVertical className="!m-0 h-4 w-4" />
          </Button>
        </TableCell>
      </TableRow>))}
    </>
  );
}

export default memo(TableSkeleton);
