import { PlusIcon, XIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { ScrollArea } from '../../../components/ui/scroll-area';

function MenuListDialog({ data, selectedItems, onToggleMenu }) {
  const [search, setSearch] = useState('');
  const filteredData = useMemo(() => {
    if (data === undefined) {
      return [];
    }
    if (search === '') {
      return data;
    }
          return data.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()));
  },
    [data, search]);

  const isItemSelected = id => {
    return selectedItems.some(item => item.id === id);
  };

  return (
    <Dialog >
      <DialogTrigger asChild>
        <Button type='button' className='bg-rose-500 w-[200px] !m-0 max-lg:w-full'>
          <PlusIcon />
          <span className='!text-white'>منوها</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-7xl max-h-[90%] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-right">منوها</DialogTitle>
        </DialogHeader>
        <Input placeholder="جستجو" className="mb-4" value={search} onChange={(e) => setSearch(e.target.value)} />
        <ScrollArea dir='rtl' className="h-[512px] w-full rounded-md border p-4">
          <div className="grid grid-cols-4 max-lg:grid-cols-2 items-center gap-2 ">
            {filteredData.map((menu) => (
              <Button variant={isItemSelected(menu.id) ? 'default' : 'outline'} type='button' key={menu.id} className='!m-0' onClick={() => onToggleMenu(menu)}>
                {menu.name}
                {isItemSelected(menu.id) ? <XIcon className='w-4 h-4 shrink-0' /> : null}
              </Button>
            ))}
          </div>
        </ScrollArea>

      </DialogContent>
    </Dialog>
  )
}

export default MenuListDialog