
import { ChevronsUpDownIcon, MoreVertical, TrashIcon, UserPenIcon } from "lucide-react"

import { Link } from "react-router-dom"
import { Button } from "../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem, DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu"
import { cn } from "../../../lib/utils"

export const columns = (onEditRole) => [
  {
    accessorKey: "name",
    label: 'نام نقش',
    header: ({ column }) => {
      return (
        <Button
          className="text-center w-max"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          نام نقش
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const { name, id } = row.original;
      return <div className="flex items-center justify-center gap-x-2">
        <Link to={`#`}>{name}</Link>
      </div>
    },
  },
  {
    accessorKey: "persianName",
    label: 'نام فارسی نقش',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          نام فارسی نقش
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
  },
  {
    accessorKey: "creationDate",
    label: 'تاریخ ایجاد',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          تاریخ ایجاد
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const { creationDate } = row.original;
      return <div className="flex items-center justify-center gap-x-2">
        <span dir="ltr" className="!text-gray-800">{creationDate}</span>
      </div>
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const { id } = row.original

      return (
        <DropdownMenu dir="rtl">
          <DropdownMenuTrigger asChild className={cn(!!onEditRole ? '' : 'hidden')}>
            <Button disabled={!!!onEditRole} variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreVertical className="!m-0 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
            >
              <UserPenIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">مشاهده و ویرایش</span>
            </DropdownMenuItem>
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
            >
              <TrashIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">حذف</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
]
