
import { memo, useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { cn } from "../../../lib/utils";
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeft, ChevronsRight } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";

function PaymentHistory({
  displayHistory,
  payments,
  onToggleDisplayAccounts,
  onGetPaymentHistory,
  paymentHistoryId
}) {

  const handlePageChange = (page) => {
    onGetPaymentHistory(paymentHistoryId, payments.pageSize, page)
  };
  const handlePageSizeChange = (pageSize) => {
    onGetPaymentHistory(paymentHistoryId, pageSize, 1)
  };

  const getPaymentStatus = useCallback((paymentCode) => {
    switch (paymentCode) {
      case "0":
        return "بارگذاری شده";
      case "1":
        return "آماده برای پرداخت";
      case "2":
        return "پرداخت شده";
      case "3":
        return "پرداخت ناموفق";
      default:
        return "نامشخص";
    }
  }, []);

  return (
    <Dialog
      dir="rtl"
      open={displayHistory}
      onOpenChange={onToggleDisplayAccounts}
    >
      <DialogContent className='max-w-5xl'>
        <DialogHeader>
          <DialogTitle className="text-center">
            تاریخچه پرداخت‌ها
          </DialogTitle>
        </DialogHeader>
        {!payments.list || payments.list.length < 1 ? <h2 className="!my-12 text-xl font-semibold text-gray-800 text-center">پرداختی برای نمایش وجود ندارد</h2>
          : <Table>
            <TableHeader className="!bg-white">
              <TableRow>
                <TableHead className="w-[100px] py-0.5 [&>button]:!m-0 [&>button]:max-md:text-xs text-center">
                  نام بانک
                </TableHead>
                <TableHead className="py-0.5 [&>button]:!m-0 [&>button]:max-md:text-xs text-center">
                  شماره شبا
                </TableHead>
                <TableHead className="py-0.5 [&>button]:!m-0 [&>button]:max-md:text-xs text-center">
                  مبلغ
                </TableHead>
                <TableHead className="py-0.5 [&>button]:!m-0 [&>button]:max-md:text-xs text-center">
                  تاریخ
                </TableHead>
                <TableHead className="py-0.5 [&>button]:!m-0 [&>button]:max-md:text-xs text-center">
                  وضعیت
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {payments.list.map((payment, index) => (
                <TableRow
                  className={cn(index % 2 === 0 ? "bg-blue-50" : "bg-white")}
                  key={payment.id}
                >
                  <TableCell className="font-medium !bg-transparent py-2 max-md:text-xs">
                    {payment.bankName}
                  </TableCell>
                  <TableCell className="!bg-transparent py-2 max-md:text-xs">
                    {payment.iban}
                  </TableCell>
                  <TableCell className="!bg-transparent py-2 max-md:text-xs">
                    {payment.amount}
                  </TableCell>
                  <TableCell dir="ltr" className="!bg-transparent py-2 max-md:text-xs">
                    {payment.date}
                  </TableCell>
                  <TableCell className="!bg-transparent py-2 max-md:text-xs">
                    {getPaymentStatus(payment.status)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>}

        <div className="flex items-center justify-between w-full">
          <Select
            dir="rtl"
            onValueChange={handlePageSizeChange}
            value={payments.pageSize?.toString()}
          >
            <SelectTrigger className="!m-0 w-[180px] [&>span]:!text-gray-800 [&>svg]:!m-0 max-md:w-full">
              <SelectValue placeholder="تعداد نتایج در صفحه" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="[&>span]:!text-gray-800" value="1">
                1
              </SelectItem>
              <SelectItem className="[&>span]:!text-gray-800" value="5">
                5
              </SelectItem>
              <SelectItem className="[&>span]:!text-gray-800" value="10">
                10
              </SelectItem>
              <SelectItem className="[&>span]:!text-gray-800" value="15">
                15
              </SelectItem>
              <SelectItem className="[&>span]:!text-gray-800" value="20">
                20
              </SelectItem>
            </SelectContent>
          </Select>
          <div className="flex gap-x-4 items-center max-md:justify-between max-md:w-full">
            <div className="flex w-[100px] items-center justify-center text-sm font-medium">
              صفحه {payments.page} از {payments.totalPage}
            </div>

            <div className="flex items-center gap-x-2">
              <Button
                variant="outline"
                className="!m-0 hidden h-8 w-8 p-0 lg:flex"
                onClick={() => handlePageChange(payments.pageSize, 1)}
                disabled={payments.page === 1}
              >
                <span className="sr-only">Go to first page</span>
                <ChevronsRight className="!m-0 h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="!m-0 h-8 w-8 p-0"
                onClick={() => handlePageChange(payments.page - 1)}
                disabled={payments.page === 1}
              >
                <span className="sr-only">Go to previous page</span>
                <ChevronRightIcon className="!m-0 h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="!m-0 h-8 w-8 p-0"
                onClick={() => handlePageChange(payments.page + 1)}
                disabled={payments.page === payments.totalPage}
              >
                <span className="sr-only">Go to next page</span>
                <ChevronLeftIcon className="!m-0 h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="!m-0 hidden h-8 w-8 p-0 lg:flex"
                onClick={() => handlePageChange(payments.totalPage)}
                disabled={payments.page === payments.totalPage}
              >
                <span className="sr-only">Go to last page</span>
                <ChevronsLeft className="!m-0 h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default memo(PaymentHistory);
