import { PlusIcon, XIcon } from 'lucide-react';
import { useMemo, useState } from 'react';
import { Button } from '../../../components/ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../../../components/ui/dialog';
import { Input } from '../../../components/ui/input';
import { ScrollArea } from '../../../components/ui/scroll-area';

function PermissionsDialog({ data, selectedItems, isOpen, onToggle, onTogglePermission }) {
  const [search, setSearch] = useState('');
  const filteredData = useMemo(() => {
    if (data === undefined) {
      return [];
    }
    if (search === '') {
      return data;
    }
    return data.filter((item) =>
      item.toLowerCase().includes(search.toLowerCase())
    )
  },
    [data, search]);

    const isItemSelected = (item) => {
      return selectedItems.includes(item);
    };

  return (
    <Dialog >
      <DialogTrigger asChild>
        <Button type='button' className='bg-rose-500 w-[200px] !m-0 max-lg:w-full'>
          <PlusIcon />
          <span className='!text-white'>افزودن دسترسی</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-7xl max-h-[90%] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-right">افزودن دسترسی</DialogTitle>
        </DialogHeader>
        <Input placeholder="جستجو" className="mb-4" value={search} onChange={(e) => setSearch(e.target.value)} />
        <ScrollArea dir='rtl' className="h-[512px] w-full rounded-md border p-4">
        <div className="grid grid-cols-4 max-lg:grid-cols-2 items-center gap-2 ">
        {filteredData.map((permission) => (
            <Button variant={isItemSelected(permission) ? 'default' : 'outline'} type='button' key={permission} className='!m-0' onClick={() => onTogglePermission(permission)}>
              {permission.split('_v1_')[1]}
            {isItemSelected(permission) ? <XIcon className='w-4 h-4 shrink-0' /> : null}
              </Button>
        ))}
        </div>
        </ScrollArea>

      </DialogContent>
    </Dialog>
  )
}

export default PermissionsDialog