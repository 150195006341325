import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import * as Api from "../api";
import { notification } from "../state/action";

const useUploadImageFiles = () => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: (params) => Api.UploadImagesFiles(params),
    onError: (e) =>
      dispatch(
        notification({
          message: e.response.data.Error.Message,
          type: "err",
          id: new Date().getSeconds(),
        })
      ),
    onSuccess: () => {
      dispatch(
        notification({
          message: "فایل با موفقیت آپلود شد",
          type: "suc",
          id: new Date().getSeconds(),
        })
      );
    },
  });
  return mutation;
};

export default {
  useUploadImageFiles,
};
