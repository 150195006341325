import { BankAccount } from "../types/data-export";

const exportCSV = (data: any[]) => {
  if (!data || data.length === 0) {
    console.error('No data available to export');
    return;
  }

  const csvRows = [];

  const headers = [
    'ردیف',
    'نام و نام خانوادگی',
    'شماره تلفن',
    'کد ملی',
    'کد پستی',
    'وضعیت کارت ملی',
    'خارجی',
    'حساب‌های بانکی'
  ];
  csvRows.push(headers.join(','));

  data.forEach((row, index) => {
    const values = [
      index + 1,
      row.name || '',
      row.mobileNumber || '',
      row.nationalCode || '',
      row.zipCode || '',
      row.isExpired ? 'نامعتبر' : 'معتبر',
      row.isForeign ? 'بله' : 'خیر',
      row.customerBankAccountInfoListModels.length > 0 ? row.customerBankAccountInfoListModels.map((account: BankAccount) => {
        return `${account.bankName}: ${account.shebaNumber}`;
      }).join(' - ') : ''
    ];
    csvRows.push(values.join(','));
  });

  const bom = '\uFEFF';
  const csvContent = bom + csvRows.join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', 'data.csv');
  a.click();
};

export { exportCSV };
