import { DownloadIcon, FilterIcon, PlusIcon, SearchIcon, XIcon } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import { Badge } from "../../../components/ui/badge";
import { Button } from "../../../components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../../../components/ui/dropdown-menu";
import { Input } from "../../../components/ui/input";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";

function TableToolbar({
  onSearch,
  bankName,
  setBankName,
  onBanksListExport,
  onRemoveFilter
}) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  return (
    <div className="w-full flex max-md:flex-col items-center justify-end max-md:justify-center p-2 gap-x-4">
      <div className="w-full p-2 flex flex-wrap gap-2">
        {urlParams.get("bankName") && (
          <Badge className="cursor-pointer" onClick={() => onRemoveFilter("bankName")}>
            <XIcon className="w-3 h-3" />
            نام بانک: {urlParams.get("bankName")}
          </Badge>
        )}
      </div>
      <div className="flex max-md:flex-col-reverse items-center justify-end gap-4 max-md:w-full min-w-[50%]">
        {onBanksListExport ? <DropdownMenu dir="rtl">
          <DropdownMenuTrigger asChild>
            <Button className="!m-0 flex items-center bg-white hover:bg-slate-200 text-gray-800 rounded-lg border border-slate-200 max-md:w-full">
              <DownloadIcon className="w-4 h-4 shrink-0" />
              <span className="!text-gray-800 text-xs">دریافت اطلاعات</span>
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={() => onBanksListExport("xlsx")} className="text-gray-800 cursor-pointer">
              <span className="text-gray-800">دریافت با فرمت XLSX</span>
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => onBanksListExport("csv")} className="text-gray-800 cursor-pointer">
              <span className="text-gray-800">دریافت با فرمت CSV</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu> : null}

        <Link to='/add-new-bank' className="!m-0 h-10 px-4 py-2 flex items-center justify-center bg-white hover:bg-slate-200 text-gray-800 rounded-lg border border-slate-200 max-md:w-full">
          <PlusIcon className="w-4 h-4 shrink-0" />
          <span className="!text-gray-800 text-xs whitespace-nowrap">افزودن بانک</span>
        </Link>

        <Popover>
          <PopoverTrigger className="!m-0 max-md:w-full">
            <Button className="!m-0 flex items-center bg-white hover:bg-slate-200 text-gray-800 rounded-lg border border-slate-200 max-md:w-full">
              <FilterIcon className="w-4 h-4 shrink-0" />
              <span className="!text-gray-800 text-xs">فیلترها</span>
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <form onSubmit={onSearch} className="w-full flex flex-col items-stretch gap-y-2">
              <div className="w-full">
                <Input defaultValue={urlParams.get("name")}
                  placeholder="نام بانک"
                  type="text"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  inputMode="numeric"
                />
              </div>
              <Button className="!m-0 flex items-center rounded-lg border border-slate-200 max-md:w-full" type="submit">
                <SearchIcon className="w-4 h-4 shrink-0" />
                <span className="!text-white">جستجو</span>
              </Button>
            </form>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
}

export default TableToolbar;
